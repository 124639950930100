@font-face {
  font-family: futura-ps;
  src: url("https://res.cloudinary.com/dlq4uayvr/raw/upload/v1621045493/fonts/futura-ps_gt6vkw.otf") format("opentype");
}

@font-face {
  font-family: garamond-reg;
  src: url("https://res.cloudinary.com/dlq4uayvr/raw/upload/v1621045493/fonts/garamond-reg_eat6sm.otf") format("opentype");
}
  

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.join_outer_container{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  position: fixed;
  left: 0;
}  
  
.joinAboutContainer{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1A1A1D;
    border-radius: 8px;
    height: 60%;
    width: 35%;
}
  .joinInnerContainer {
    padding: 2vw;
    position: fixed;
  }
  
  .joinInput {
    padding: 15px 20px;
    width: 100%;
    font-size: 6vw;
    outline: none;
    border-radius: 10px;
    font-family: 'garamond-reg';
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3vh;
    background-color: #F3F3F3;
    border: 3px dotted;
  }

  .joinInput::placeholder {
    color: black;
  } 
  
  .heading {
    color: white;
    font-size: 10vw;
    padding-bottom: 10px;
    font-family: 'garamond-reg';
    font-weight: 100;
    margin: 0;
    -webkit-text-stroke: 4px #6d3708;
  }

  .join_moving_img{
    position: absolute;
    display: none;
  }

  .joinInnerContainer img{
    width: 65px;
  }


  
  .button {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    background: black;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: 3px dol;
    width: -moz-fit-content;
    width: fit-content;
    font-family: futura-ps;
    font-size: 2vw;
    padding-top: 2vw;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .join_outer_container {
      height: 100%;
    }
  
    .joinInnerContainer {
      width: 90%;
    }
  }
  
  button:focus {
    outline: 0;
  }


  @media only screen and (max-width: 600px) {
    .heading {
      color: white;
      font-size: 8vw;
      padding-bottom: 10px;
      font-family: 'garamond-reg';
      font-weight: 100;
      margin: 0;
      -webkit-text-stroke: 1px #6d3708;
    }

    .button{
      padding: 10px;
      font-size: 4vw;
    }
  }
  
  
