@font-face {
  font-family: futura-ps;
  src: url("https://res.cloudinary.com/dlq4uayvr/raw/upload/v1621045493/fonts/futura-ps_gt6vkw.otf") format("opentype");
}

@font-face {
  font-family: garamond-reg;
  src: url("https://res.cloudinary.com/dlq4uayvr/raw/upload/v1621045493/fonts/garamond-reg_eat6sm.otf") format("opentype");
}

.form {
  display: flex;
  border-top: 2px dotted black;
  position: fixed;
  bottom: 0;
  width: 22vw;
  height: 7vh;
  }
  
  .input {
    border: none;
    border-radius: 0;
    padding: 5%;
    width: 80%;
    font-size: 1.2em;
    color: black;
    font-family: garamond-reg;
  }
  
  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  
  .sendButton {
    font-family: futura-ps;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #6d3708;
    padding: 20px;
    display: inline-block;
    border: none;
  }


  @media only screen and (max-width: 600px) {
    .form {
      display: flex;
      position: fixed;
      bottom: 0;
      width: inherit;
      height: 7vh;
      }

      .input{
        width: 100%;
      }
      
}
