@font-face {
  font-family: futura-ps;
  src: url("fonts/futura-ps.otf") format("opentype");
}

@font-face {
  font-family: garamond-reg;
  src: url("fonts/garamond-reg.otf") format("truetype");
}


a{
  text-decoration: none;
}

@font-face {
  font-family: garamond-reg;
  src: local('garamond-reg'), url(./fonts/garamond-reg.otf) format('truetype');
}

html{
  overflow: hidden;
}

body{
  background-color: black;
  margin: 0;
}

.mobile_donate{
  display: none;
}

.iframe_container{
  width: 62vw;
}


.stream_video_main{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.caption_link_arrow{
  padding-left: 5px;
}
.banner_logo{
  color: black;
  height: 14vh;
  background-color: white;
  margin-bottom: 1vw;
  width: 98%;
  border: 2px dotted #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_logo img{
  width: 55%;
}

.stream_video_main_inner{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  width: 98%;
  padding: 1vw;
  border: 2px dotted white;
  height: 75.5vh;
}

.overlay_text h1{
  position: absolute;
  width: 100vw;
  top: 0;
  margin: 0;
  text-align: center;
  font-size: 6.4vw;
  color: #fff;
  font-family: "futura-ps";
  /* -webkit-text-stroke: 4px #5d6880; */
}

.raining_imgs{
  position: absolute;
  z-index: 10;
  display: none;
}

.raining_imgs img{
  width: 420px;
  animation: moveX 6.1s linear 0s infinite alternate, moveY 6.8s linear 0s infinite alternate
}

#countdown_animate{
  font-family: "garamond-reg";
  -webkit-text-stroke: white;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.notification_outer{
  position: fixed;
  z-index: 100;
  top: 0;
}

.notification_inner{
  background-color: white;
  padding: 10px;
  border-radius: 7px;
  margin: 10px;
  border: 1px solid;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  transform-origin: 0;
  display: flex;
  align-items: center;
}


.notification_inner h2{
  color: black;
  font-family: garamond-reg;
  display: inline;
  font-size: 14px;
  margin: 0;
}

.notification_inner span{
  color: red;
  font-family: futura-ps;
  padding-right: 5px;
}

.notification_inner span::after{
  content: " : ";
}

.stream_cta{
  position: fixed;
  width: 100vw;
  bottom: -4vw;
  margin: 0;
  text-align: center;
}

.stream_cta h2{
  cursor: pointer;
  font-size: 15.4vw;
  color: #fff;
  font-family: "futura-ps";
  /* -webkit-text-stroke: 4px #5d6880; */
  margin: 0;
}


.background_img{
  position: absolute;
  z-index: 0;
  left: 0;
}

.background_img img{
  height: 100vh;
}


.overlay_background{
  background-color: black;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  position: relative;
  align-items: center;
  top: 0;
  display: flex;
  left: 0;
  z-index: 100;
  background-image: url("https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1487101159/water_PNG3317_tsc2jc.png");
}

.overlay_background_inner{
  width: 72vw;
}

.overlay_background h1{
  color: white;
  width: fit-content;
  font-size: 6vw;
}

.notifications_container{
  position: absolute;
  left: 0;
  width: 35vw;
}

.notification_avatar_container{
  width: 20px;
  padding: 10px;
}

.notification_avatar_container img{
  width: 100%;
}

.first_fold{
  background-image: url("https://res.cloudinary.com/dlq4uayvr/image/upload/v1618359530/assets/142-1426460_mountains-natur-regenbogen-wallpaper-rainbow-in-the-mountains_wsdtyu.jpg");
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.splitted_string_container,
.splitted_string_container div{
  display: inline-block;
}

.toggle_animations h2 span{
  background-color: black;
  color: white;
  width: 100;
  border: 4px solid white;
}

.marquee_component{
  position: absolute;
  z-index: 100;
  background-color: white;
  margin: 1vw;
  padding: 1vw;
  border: 2px solid;
  margin: 0;
  width: 20vw;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 10px;
}

#marquee_donate{
  top: 17vw;
  left: 3vw;
  animation: 10s infinite rotate;
  width: 30vw;
}

.zoom_cta_component{
  position: fixed;
  top: 0;
}

#marquee_donate_2{
  display: none;
  bottom: 12vw;
  left: 13vw;
  animation: 16s infinite rotate;
}

#marquee_host{
  bottom: 13vw;
  right: 3vw;
  animation: 10s infinite rotate;
}

#marquee_host_2{
  display: none;
  top: 13vw;
  right: 23vw;
  animation: 13s infinite rotate;
}

.marquee_component h1{
  margin: 0;
  font-family: 'garamond-reg';
  font-size: 1.8vw;
  line-height: 2;
}

.timestamp{
  margin-top: 10vw;
}

.timestamp span{
  color: white;
  -webkit-text-stroke: 2px white;
  font-family: "garamond-reg";
  font-size: 16vw;
}

.chat_container{
  z-index: 100000;
  right: 0;
  width: 22vw;
  height: 100vh;
  top: 0;
  position: fixed;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.first_fold marquee{
  color: white;
  font-size: 6vw;
  font-family: 'futura-ps';
  position: absolute;
  bottom: -1vh;
  line-height: 1;
}

.first_fold marquee img{
  width: 20px;
}

.first_fold h1{
  color: black;
  font-family: 'futura-ps';
  font-size: 8vw;
  width: 76vw;
  margin: 0;
  text-shadow: 1px 2px 20px #fff;
  text-align: center;
}

.first_fold h2{
  color: white;
  font-family: 'futura-ps';
  font-size: 4vw;
  width: 95vw;
  text-align: center;
  animation: 5s infinite scale3D;
  text-shadow: 1px 2px 20px #fff;
}

.donate_buttons{
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.donate_buttons img{
  width: 33px;
  margin-right: 10px;
}

.donate_buttons span{
  text-align: center;
  font-family: 'garamond-reg';
  font-size: 114px;
  color: white;
  -webkit-text-stroke: 1px black;
  animation: 5s infinite scale3D;
}

.first_fold img{
  position: fixed;
  width: 100vw;
}



.first_fold .first_img{
  width: 70px;
  left: 5vw;
  top: 7vh;
  animation: 7s bounce infinite;
}

.first_fold .second_img{
  position: fixed;
  z-index: 100000;
  right: 3vw;
  bottom: 20vh;
  width: 140px;
  animation: 3s spinner infinite;
  z-index: 1;
}

.marquee_performers{
  position: fixed;
  top: 0;
  z-index: 100;
  display: none;
}

.marquee_performers marquee{
  color: white;
  font-family: 'futura-ps';
  text-transform: uppercase;
  -webkit-text-stroke: 4px #6d3708;
  height: 130px;
}

.marquee_performers marquee div{
  display: inline;
  height: 100%;
  margin: 2vw;
}

.marquee_performers img{
  width: 90px;
  padding: 0.2vw;
  border: 3px dotted #6d3708;
}

.performers_fold{
  position: relative;
  background: url("https://res.cloudinary.com/dlq4uayvr/image/upload/v1618359580/assets/232091_fiazx9.jpg") no-repeat center center fixed;
  background-size: cover;
  z-index: 1000;
  padding: 2vw;
}

.performers_fold h1{
  color: white;
  font-size: 3vw;
  font-family: 'garamond-reg';
  position: relative;
  margin: 0;
  padding-top: 3vw;
  padding-bottom: 3vw;
  top: 0;
  -webkit-text-stroke: 1px black;
  text-align: center;
}

.performers_fold .performers_list div{
  display: inline-block;
  margin: 2vw;
}

.stream_container{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: black;
  width: 78vw;
  /* background: url(https://res.cloudinary.com/dlq4uayvr/image/upload/v1621227538/assets/EeM11zPXsAEFB2-_dzk2gc_nsrxar.jpg); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;
  flex-direction: column;
}
  
.top_background{
  width: 100%;
  height: 14vh;
  margin-bottom: 1vw;
  /* background: url(https://res.cloudinary.com/dlq4uayvr/image/upload/v1618359491/assets/maligne-rainbow-c2a9-2011-christopher-martin_yztpv9.jpg); */
  border: 2px dotted black;
  display: flex;
  align-items: center;
  padding-left: 2vw;
  justify-content: center;
  background-color: white;
}

.top_background img{
  width: 55%;
}

.top_background h1{
  margin: 0;
  font-family: "futura-ps";
  font-size: 3vw;
  padding: 1vw;
}


.top_background div{
  display: inline;
  margin-left: 1vw;
}

.top_background div img{
  width: 2vw;
}

.donate_marquee{
  height: 60%;
  background-color: black;
  border-bottom: 2px dotted black;
  display: flex;
  align-items: center;
  font-family: "garamond-reg";
  color: white;
  font-size: 1.5vw;
}

.top_background_2{
  width: 92%;
  background-color: white;
  height: 14vh;
  margin-bottom: 1vw;
  margin-bottom: 1vw;
  border: 2px dotted black;
}

.close_caption_link{
  padding: 1vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close_caption_link span{
  font-size: 1.2vw;
  font-family: "futura-ps";
  text-transform: uppercase;
  line-height: 1.6;
}

.close_caption{
  height: 40%;
  transition: 2s;
}

.close_caption iframe{
  width: 100%;
  height: 95%;
}

#vimeo_embed{
  background-color: black;
  height: 75.5vh;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dotted white;
}

.iframe_vimeo{
  height: 96%;
  width: 96%;
}

.information_viewers_component{
  position: fixed;
  width: 76vw;
  bottom: 0;
  display: flex;
  z-index: 10;
  align-items: center;
  margin: 0.5vw;
  margin-left: 1vw;
  margin-right: 1vw;
}

.information_viewers_component_top{
  position: fixed;
  width: fit-content;
  top: 0;
  z-index: 10;
  align-items: center;
  margin: 0.5vw;
  margin-left: 1vw;
  margin-right: 1vw;
  justify-content: flex-end;
}


.user_list_clicked{
  background-color: black;
  color: white;
  padding-top: 1vw;
  margin-top: 1vw;
  border: 4px dotted;
  padding: 1vw;
  height: 30vh;
  overflow: scroll;
}

.user_list_clicked ul{
  margin: 0;
  padding: 0;
}

.user_list_clicked ul li{
  list-style-type: none; 
} 

.love_animation{
  position: fixed;
  z-index: 1;
  animation: animationFrames 2s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}

.information_div{ 
  z-index: 1;
};

.information_div:first-child{
  left: 0;
}
.information_div:last-child{
  right: 0;
}

.number_of_viewers{
  z-index: 1;
  position: fixed;
  right: 1vw;
  bottom: 0;
  background-color: transparent;
}

.information_div span{
  font-family: futura-ps;
  color: white;
  font-size: 13px;
  width: fit-content;
  cursor: pointer;
}


.marquee_stream_video{
  position: fixed;
  top: 0;
  left: 0;
}

.marquee_stream_video marquee{
  font-family: ;
}

#marquee_stream_container{
  left: 1vw;
  top: 1vw;
  cursor: move;
}

.chat{
  width: 30vw;
  height: 50vh;
  background-color: white;
  border: 1px solid;
  position: relative;
}

.chat footer{
  position: absolute;
  bottom: 0;
}

.chat_stream{
  color: black;
  height: 46vh;
  background-color: white;
  z-index: 1000;
  position: fixed;
  left: 1vw;
  top: 100px;
  width: 27vw;
}

#marquee_stream_container_2{
  right: 1vw;
  bottom: 1vw;
  cursor: move;
}

.css-13azwyo, [data-css-13azwyo]{
  padding: 2.5% 0;
  overflow: scroll;
  flex: auto;
  height: 80vh !important;
  background-color: #e2e2e2;
}

.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background: url("https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1487101159/water_PNG3317_tsc2jc.png");
}

.iframe_1 {
  position: absolute;
  top: 0.5vw;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.85);
}

.iframe_chat_container{
  height: 75.5vh;
  width: 92%;
}

.iframe_2{
  width: 100%;
  border: 2px dotted black;
  height: inherit;
  padding: 0.7vw;
  background-color: white;
}

.performers_fold .performers_list div span{
  background-color: white;
  font-family: 'garamond-reg';
  font-size: 35px;
  font-weight: 100;
  padding: 20px;
  border-radius: 11px;
  border: 1px solid black;
}

.first_fold .last_img{
  right: 40px;
  width: 200px;
}


#ps_twitch{
  z-index: 1000;
}

.notifications_container div{
  position: relative;
}


.img_rolling_1{
  position: absolute;
  width: 13%;
  z-index: -1;
  left: 3vw;
  top: 3vw;
  display: none;
}

.img_rolling_2{
  position: absolute;
  width: 10%;
  right: 100px;
  top: 21px;
  z-index: -1;
  display: none;
}

.moving_imgs_stream{
  display: none;
}

.moving_imgs_stream img:first-child{
  position: fixed;
  width: 69px;
  left: 3vw;
  top: 13vh;
  z-index: 1000;
  animation: 10s bounce infinite;
}

.moving_imgs_stream img:nth-child(2){
  position: fixed;
  left: 3vw;
  bottom: 9vh;
  width: 140px;
  z-index: 1000;
  animation: 10s spinner infinite;
}

.toggle_animations{
  position: fixed;
  left: 2vw;
  top: 13vh;
  font-size: 3vw;
  z-index: 10;
  display: none;
}

.toggle_animations h2{
  color: black;
  background-color: white;
  font-size: 1.5vw;
  padding: 1vw;
  border-radius: 10px;
  border: 2px solid;
  font-family: 'futura-ps';
}

.moving_imgs_stream img:nth-child(3){
  position: fixed;
  width: 140px;
  right: 17vw;
  top: 13vh;
  z-index: 1000;
  animation: 7s spinner infinite;
  z-index: 1000000;
}


.moving_imgs_stream img:nth-child(4){
  position: fixed;
  width: 120px;
  right: 23vw;
  bottom: 3vh;
  z-index: 1000;
  animation: 10s bounce2 infinite;
}

.performers_marquee{
  position: fixed;
  z-index: 10;
  color: white;
  width: 100%;
  font-size: 6.4vw;
  text-transform: uppercase;
  font-family: "garamond-reg";
  -webkit-text-stroke: 4px #5d6880;
  color: white;
}

#performers_marquee_first{
  -webkit-text-stroke: 4px #5d6880;
  top: 0;
}

@keyframes animationFrames{
  0% {
    bottom: 3vw;
    left: 3vw;
    display: block;
  }
  99% {
    bottom: 10vw;
    left: 13vw;
    display: block;
  }
  100% {
    bottom: 10vw;
    left: 13vw;
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .overlay_text h1{
    -webkit-text-stroke: 1px #5d6880;
    font-size: 5.5vw;
  }

  .top_background,
  #vimeo_embed{
    width: 95%;
  }

  .top_background{
    height: auto;
    padding: 10px;
    margin-bottom: 2vw;
  }

  .iframe_container{
    width: 85vw;
  }

  .banner_logo img{
    width: 90%;
  }

  .stream_container {
    height: 100vh;
    width: 100vw;
    padding: 0;
  }

  .iframe_chat_container{
    height: 30vh;
  }

  .chat_container{
    background-color: transparent;
    height: 44vh;
    top: inherit;
    bottom: 0;
    display: none;
  }

  .iframe_1{
    transform: scale(0.7);
    top: -10vw;
    height: 71%;
  }

  .cta_mobile{
    background-color: black;
    height: 30vh;
    width: 100vw;
    position: fixed;
    z-index: 100;
    bottom: 0;
  }

  .mobile_donate{
    color: white;
    display: block;
    width: 100%;
    background-color: black;
    margin-top: 2vw;
    font-size: 5.5vw;
    font-family: "garamond-reg";
    border: 2px dotted;
    padding-top: 4vw;
  }

  .stream_video_main_inner{
    border: none;
  }

  .stream_video_main_inner{
    height: auto;
  }

  .banner_logo{
    height: auto;
    width: 85vw;
    margin-bottom: 3vw;
    padding: 3vw;
  }



  #vimeo_embed{
    height: 39.5vh;
  }

  .css-13azwyo, [data-css-13azwyo]{
    height: 30vh !important;
  }

  .messages{
    height: 30vh !important;
  }  
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

    .overlay_text h1{
      font-size: 5vw;
    }

    .banner_logo{
      border: none;
      height: 10vh;
      display: none;
    }

    .iframe_container{
      width: 53vw;
    }

    .banner_logo img{
      width: 40%;
    }

    .stream_video_main_inner{
      border: none;
    }

    .stream_cta h2{
      font-size: 10vw;
      margin-bottom: 1vw;
    }

    .stream_container{
      width: 100vw;
      padding: 0;
    }

    .chat_container{
      display: none;
    }

}


